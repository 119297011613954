<template>
  <!-- 'padding-right': '5px', -->
  <div
    :style="{
      background: '#f5f5f5',
      height: heightTree,
      'min-width': '240px',
    }"
  >
    <Tree
      v-if="gData.length > 0"
      :style="{
        'overflow-x': 'auto',
        height: heightTree,
        padding: '0 10px',
        'min-width': '240px',
        'border-radius': '4px',
        background: '#f5f5f5',
      }"
      :tree-data="gData"
      v-model:selectedKeys="selectedKeys"
      v-model:checkedKeys="checkedKeys"
      :auto-expand-parent="autoExpandParent"
      :replace-fields="{
        children: 'childList',
        title: 'pdName',
        key: modul != 2 ? 'pdCode' : 'key',
      }"
      :blockNode="true"
      show-icon
      checkable
      @check="check"
      :checkStrictly="checkStrictly"
      :defaultExpandAll="checkStrictly"
      @expand="onExpand"
      :expandedKeys="expandedKeys"
    >
      <template #switcherIcon>
        <DownOutlined />
      </template>
      <template #title="{ pdName }">
        <!-- {{tag+id}} -->
        <div class="tagBox" :title="pdName">
          {{ pdName }}
        </div>
      </template>
    </Tree>
  </div>
</template>
<script>
import { Input, Tree, Popover, Modal } from "ant-design-vue";
import {
  SearchOutlined,
  MoreOutlined,
  CloseOutlined,
  PlusOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FolderAddFilled,
  FolderOpenFilled,
  DownOutlined,
  ApartmentOutlined,
} from "@ant-design/icons-vue";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
export default {
  components: {
    FolderOpenFilled,
    FolderAddFilled,
    Input,
    Tree,
    SearchOutlined,
    Popover,
    MoreOutlined,
    CloseOutlined,
    PlusOutlined,
    EditOutlined,
    Modal,
    DecorateModalTitle,
    ExclamationCircleOutlined,
    DownOutlined,
    ApartmentOutlined,
  },
  props: {
    gData: {
      type: Array,
      default: [],
    },
    checkedKeys: {
      type: Array,
      default: [],
    },
    modul: {
      type: Number,
      default: 0,
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      height: "500px",
      heightTree: "500px",
      selectedKeys: [],
      expandedKeys: ["ALL", "0"],
      autoExpandParent: true,
    };
  },
  methods: {
    check(checkedKeys, e) {
      // console.log(e.checkedNodes[0].props.status);
      // console.log(checkedKeys);
      this.$emit("changeCheckedKeys", this.checkedKeys, this.modul);
      this.$emit("changeDisabled");
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    getStatus1List(res, array) {
      array.forEach((element) => {
        if (element.status == 1) {
          res.push(element.pdCode);
        }
        if (element.childList) {
          this.getStatus1List(res, element.childList);
        }
      });
    },
  },
  watch: {
    selectedKeys(af, be) {
      if (af.length === 0) {
        this.selectedKeys = be;
      }
    },
    gData() {
      if (this.modul == 1) {
        let res = [];
        this.getStatus1List(res, this.gData);
        console.log(res);
        this.onExpand(res);
      }
    },
  },
  mounted() {
    this.height = document.body.clientHeight - 212 + "px";
    this.heightTree = document.body.clientHeight - 354 + "px";
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}
.between {
  justify-content: space-between;
}
.handleBox {
  visibility: hidden;
}
.tagBox {
  padding: 0 5px;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.handle {
  > div {
    cursor: pointer;
    padding: 10px 10px;
    width: 150px;
  }
  > div:hover {
    background: #eee;
  }
}
</style>
<style lang="scss">
.qualifications {
  .ant-modal-body {
    padding: 0 34px 20px 34px;
  }
}
.ant-popover {
  z-index: 0;
}
</style>
