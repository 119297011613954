<template>
  <div class="qualifications_dataLabel">
    <Input
      placeholder="请输入标签名称进行搜索"
      v-model:value="searchValue"
      @change="onChange"
      size="small"
      style="margin: 15px 0"
      allow-clear
    >
      <template #prefix>
        <SearchOutlined />
      </template>
    </Input>
    <Tree
      v-if="gData.length"
      :style="{ 'overflow-y': 'auto', height: heightTree }"
      :expandedKeys="expandedKeys"
      :auto-expand-parent="autoExpandParent"
      :tree-data="gData"
      v-model:selectedKeys="selectedKeys"
      @expand="onExpand"
      :replace-fields="{
        children: 'nodes',
        title: 'tag',
        key: 'id',
      }"
      :blockNode="true"
      @select="selectTree"
      show-icon
    >
      <template #switcherIcon>
        <DownOutlined />
      </template>
      <template #title="{ tag, id, parId }">
        <!--#title="{ tag, id, parId, nodes }"  -->
        <div
          style="padding: 0 5px"
          class="between flex tagBox"
          :class="id === 'ALL' ? 'level0' : ''"
        >
          <span>
            <TagsOutlined
              v-if="id == 'ALL'"
              style="color: #8c8c8c; margin-right: 2px"
            />
            <TagOutlined v-else style="color: #8c8c8c; margin-right: 2px" />
            <!-- <i
              v-if="id != 'ALL'"
              class="iconfont icon-File-zuzhijiagou-F"
              style="color: #8c8c8c; position: relative; top: 2px"
            /> -->
            <span v-if="tag.indexOf(searchValue) > -1">
              {{ tag.substr(0, tag.indexOf(searchValue)) }}
              <span style="color: #f50">{{ searchValue }}</span>
              {{ tag.substr(tag.indexOf(searchValue) + searchValue.length) }}
            </span>
            <span v-else>{{ tag }}</span>
          </span>
          <span style="margin-left: 10px" class="handleBox">
            <Popover placement="rightTop">
              <template #content>
                <div class="handle">
                  <div @click="clickPop(tag, id, parId, 'add')">
                    <PlusOutlined style="margin-right: 5px" />添加数据标签
                  </div>
                  <div
                    @click="clickPop(tag, id, parId, 'del')"
                    v-if="id != 'ALL'"
                  >
                    <CloseOutlined style="margin-right: 5px" />删除数据标签
                  </div>
                  <div
                    @click="clickPop(tag, id, parId, 'edit')"
                    v-if="id != 'ALL'"
                  >
                    <EditOutlined style="margin-right: 5px" />修改数据标签
                  </div>
                </div>
              </template>
              <div class="SettingOutlinedBox">
                <MoreOutlined />
              </div>
            </Popover>
          </span>
        </div>
      </template>
    </Tree>
    <Modal
      class="qualifications"
      v-model:visible="visible"
      centered
      @ok="handleOk"
      :confirmLoading="confirmLoading"
      :zIndex="200"
      width="600px"
      :okButtonProps="{ disabled: tagName == '' }"
    >
      <template #title>
        <DecorateModalTitle :modalTitle="modalTitle" />
      </template>
      <Input
        v-model:value="tagName"
        placeholder="请输入标签名称"
        :maxlength="20"
      />
    </Modal>
  </div>
</template>
<script>
import { createVNode } from "vue";
import { Input, Tree, Popover, Modal, message } from "ant-design-vue";
import {
  SearchOutlined,
  MoreOutlined,
  CloseOutlined,
  PlusOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  ApartmentOutlined,
  FileOutlined,
  FolderAddFilled, //文件夹
  FolderOpenFilled, //被打开的文件夹
  TagsOutlined,
  TagOutlined,
} from "@ant-design/icons-vue";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import { dataCreate, dataIdUpdate, dataDel } from "@/api/terminalList.js";

//获取父节点
const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.nodes) {
      if (node.nodes.some((item) => item.id === key)) {
        parentKey = node.id;
      } else if (getParentKey(key, node.nodes)) {
        parentKey = getParentKey(key, node.nodes);
      }
    }
  }
  return parentKey;
};

export default {
  components: {
    Input,
    Tree,
    SearchOutlined,
    Popover,
    MoreOutlined,
    CloseOutlined,
    PlusOutlined,
    EditOutlined,
    Modal,
    DecorateModalTitle,
    ExclamationCircleOutlined,
    DownOutlined,
    ApartmentOutlined,
    FileOutlined,
    FolderAddFilled, //文件夹
    FolderOpenFilled, //被打开的文件夹
    TagsOutlined,
    TagOutlined,
  },
  props: {
    gData: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      height: "500px",
      heightTree: "500px",
      searchValue: "",
      expandedKeys: ["ALL"],
      autoExpandParent: true,
      selectedKeys: [],
      modalTitle: "",
      tagName: "",
      confirmLoading: false,
      visible: false,
      handleData: { type: "add", id: 0, tag: "" },
    };
  },
  methods: {
    //输入框值变化时候 手动筛选出key值 放入展开数组中
    onChange() {
      let that = this;
      let idList = [];
      function calleArr(array, childrentag) {
        for (let i = 0; i < array.length; i++) {
          var data = array[i];
          if (data[childrentag]) {
            if (data.tag.indexOf(that.searchValue) > -1) {
              idList.push(data.id);
            }
            calleArr(data[childrentag], childrentag); //自己调用自己
          } else {
            if (data.tag.indexOf(that.searchValue) > -1) {
              idList.push(data.id);
            }
          }
        }
      }
      calleArr(this.gData, "nodes");
      this.expandedKeys = idList;
      this.autoExpandParent = true;
    },
    onExpand(expandedKeys) {
      // console.log(expandedKeys);
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    //手动替换
    onExpandManual(id) {
      // console.log(e.target.previousSibling);
      // console.log(this.expandedKeys);
      // if (this.expandedKeys.includes(id)) {
      //   this.expandedKeys.forEach((el, index) => {
      //     if (el === id) {
      //       let list = [...this.expandedKeys];
      //       list.splice(index, 1);
      //       this.expandedKeys = [...list];
      //       console.log(this.expandedKeys);
      //     }
      //   });
      // } else {
      //   this.expandedKeys = [...this.expandedKeys, id];
      // }
    },
    selectTree(selectedKeys, e) {
      if (selectedKeys.length) {
        this.$emit("selectTree", selectedKeys, e.selectedNodes[0].props.tag);
      }
      // let parId = getParentKey(...selectedKeys, this.gData);
      // console.log(parId);
    },
    clickPop(tag, id, parId, type) {
      // console.log(tag, id, type);
      // 'add' 'del'  'edit'
      if (type === "del") {
        let that = this;
        Modal.confirm({
          title: "提示",
          icon: () => createVNode(ExclamationCircleOutlined),
          content: "是否确认删除该标签？",
          cancelText: "取消",
          okText: "确认",
          onOk() {
            dataDel(id)
              .then((res) => {
                if (res.data.success) {
                  message.success("删除成功");
                  that.$emit("getNewData",'isDel');
                  that.confirmLoading = false;
                  that.visible = false;
                } else {
                  that.confirmLoading = false;
                }
              })
              .catch(() => {
                that.confirmLoading = false;
              });
          },
        });
      } else {
        switch (type) {
          case "add":
            this.tagName = "";
            this.modalTitle = "添加数据标签";
            break;
          case "edit":
            this.tagName = tag;
            this.modalTitle = "修改数据标签";
            break;
          default:
            break;
        }
        this.handleData = {
          type: type,
          id: id === "ALL" ? null : id,
          tag: tag,
          parId: parId,
        };
        this.visible = true;
      }
    },
    handleOk() {
      this.confirmLoading = true;
      if (this.handleData.type === "add") {
        dataCreate({
          tag: this.tagName,
          parId: this.handleData.id || null,
        })
          .then((res) => {
            if (res.data.success) {
              this.$emit("getNewData");
              message.success("添加成功");
              this.expandedKeys = [...this.expandedKeys, res.data.data.parId];
              this.confirmLoading = false;
              this.visible = false;
            } else {
              this.confirmLoading = false;
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          });
      }
      if (this.handleData.type === "edit") {
        let id = this.handleData.id;
        let tagName = this.tagName;
        dataIdUpdate(id, tagName)
          .then((res) => {
            if (res.data.success) {
              message.success("修改成功");
              this.$emit("getNewData");
              this.confirmLoading = false;
              this.visible = false;
            } else {
              this.confirmLoading = false;
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          });
      }
    },
    //获取传参发请求
    getQuery() {
      if (this.$route.query.tag) {
        this.selectedKeys = [Number(this.$route.query.tag)];
        this.$emit("selectTree", [Number(this.$route.query.tag)], null);
      }
    },
  },
  watch: {
    selectedKeys(af, be) {
      if (af.length === 0) {
        this.selectedKeys = be;
      }
    },
  },
  mounted() {
    this.height = document.body.clientHeight - 162 + "px";
    this.heightTree = document.body.clientHeight - 304 + "px";
    this.getQuery();
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}
.between {
  justify-content: space-between;
}
.handleBox {
  visibility: hidden;
}
.tagBox:hover {
  span {
    color: #333 !important;
  }
  .handleBox {
    visibility: visible;
  }
}

.handle {
  > div {
    cursor: pointer;
    padding: 10px 10px;
    width: 150px;
  }
  > div:hover {
    background: #eee;
  }
}
</style>
<style lang="scss">
.qualifications {
  .ant-modal-body {
    padding: 0 34px 20px 34px;
  }
}
.ant-popover {
  z-index: 0;
}
// .qualifications_dataLabel
//   li.ant-tree-treenode-disabled
//   > span:not(.ant-tree-switcher),
// li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
// li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
//   cursor: pointer !important;
// }
</style>
