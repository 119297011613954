<template>
  <div>
    <div
      class="flex out"
      :style="{
        'min-height': height,
      }"
    >
      <div class="flex">
        <div
          :style="{
            'min-height': height,
            'min-width': '250px',
            'border-radius': '5px 0 0 5px',
          }"
        >
          <div class="titleLeft">数据标签管理</div>
          <div style="padding: 10px">
            <DataLabel
              ref="DataLabel"
              :gData="gData"
              @getNewData="
                (isDel) => {
                  isDel === 'isDel' ? afterDel() : getData();
                }
              "
              @selectTree="selectTree"
            />
          </div>
        </div>
      </div>
      <div
        style="
          border-left: 1px solid #ccc;
          padding: 0 32px;
          width: 100%;
          overflow: auto;
        "
      >
        <div v-if="tagCode && tagCode !== 'ALL' && tagName != '数据权限标签'">
          <div
            style="height: 60px; border-bottom: 1px solid #ccc"
            class="flex between middle"
          >
            <div style="margin-top: 20px"></div>
            <div style="margin-top: 20px">
              <span style="margin-right: 10px">有效期至:</span>
              <RadioGroup v-model:value="validity">
                <Radio :value="1">永久</Radio>
                <Radio :value="2" style="margin-left: 30px" disabled
                  >自定义</Radio
                >
                <DatePicker
                  v-model:value="datePicker"
                  :disabled="validity === 1"
                />
              </RadioGroup>
            </div>
          </div>
          <div
            class="flex"
            :style="{
              overflow: 'auto',
              'padding-bottom': '10px',
            }"
          >
            <div class="treeBox" style="padding-right: 20px">
              <div style="border-right: 1px solid #ccc; padding-right: 20px">
                <div class="title">
                  <Popover placement="top">
                    <template #content>
                      <div class="handle">员工所属本部门数据无需授权。</div>
                    </template>
                    部门数据采集授权 <QuestionCircleOutlined />
                  </Popover>
                </div>
                <DataTree
                  :gData="gData1"
                  :checkStrictly="true"
                  :checkedKeys="checkedKeys1"
                  :modul="1"
                  @changeCheckedKeys="changeCheckedKeys"
                  @changeDisabled="
                    () => {
                      disabled = false;
                    }
                  "
                />
              </div>
              <div class="icon" style="right: 8px">
                <DoubleRightOutlined style="position: relative; left: 1px" />
              </div>
            </div>
            <div class="treeBox">
              <div class="title">
                <Popover placement="top">
                  <template #content>
                    <div class="handle">员工所属本部门数据无需授权。</div>
                  </template>
                  区域授权 <QuestionCircleOutlined />
                </Popover>
              </div>
              <DataTree
                :gData="gData2"
                :checkedKeys="checkedKeys2"
                :modul="2"
                @changeCheckedKeys="changeCheckedKeys"
                @changeDisabled="
                  () => {
                    disabled = false;
                  }
                "
              />
              <div class="icon">
                <div>&</div>
              </div>
            </div>
            <div class="treeBox">
              <div class="title">
                <Popover placement="top">
                  <template #content>
                    <div class="handle">员工所属本部门数据无需授权。</div>
                  </template>
                  客户授权 <QuestionCircleOutlined />
                </Popover>
              </div>
              <DataTree
                :gData="gData3"
                :checkedKeys="checkedKeys3"
                :modul="3"
                @changeCheckedKeys="changeCheckedKeys"
                @changeDisabled="
                  () => {
                    disabled = false;
                  }
                "
              />
              <div class="icon">
                <div>&</div>
              </div>
            </div>
            <div class="treeBox">
              <div class="title">
                <Popover placement="top">
                  <template #content>
                    <div class="handle">员工所属本部门数据无需授权。</div>
                  </template>
                  渠道授权 <QuestionCircleOutlined />
                </Popover>
              </div>
              <DataTree
                :gData="gData4"
                :checkedKeys="checkedKeys4"
                :modul="4"
                @changeCheckedKeys="changeCheckedKeys"
                @changeDisabled="
                  () => {
                    disabled = false;
                  }
                "
              />
              <div class="icon">
                <div>&</div>
              </div>
            </div>
            <div class="treeBox">
              <div class="title">
                <Popover placement="top">
                  <template #content>
                    <div class="handle">员工所属本部门数据无需授权。</div>
                  </template>
                  品牌授权 <QuestionCircleOutlined />
                </Popover>
              </div>
              <DataTree
                :gData="gData5"
                :checkedKeys="checkedKeys5"
                :modul="5"
                @changeCheckedKeys="changeCheckedKeys"
                @changeDisabled="
                  () => {
                    disabled = false;
                  }
                "
              />
            </div>
          </div>
          <div class="sub">
            <Button type="primary" @click="submit()" :disabled="disabled"
              >确定</Button
            >
            <Button
              style="margin-left: 10px"
              @click="selectTree([tagCode])"
              :disabled="tagCode === '' || disabled"
              >重置</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataLabel from "@/components/qualifications/DataLabel.vue";
import DataTree from "@/components/qualifications/DataTree.vue";
import {
  Input,
  Button,
  Popover,
  message,
  Radio,
  DatePicker,
} from "ant-design-vue";
import {
  SearchOutlined,
  QuestionCircleOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons-vue";
import { dataAll, dataList, saveConfiguration } from "@/api/terminalList.js";
let { Group: RadioGroup } = Radio;
//获取父节点
const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.nodes) {
      if (node.nodes.some((item) => item.id == key)) {
        parentKey = node.id;
      } else if (getParentKey(key, node.nodes)) {
        parentKey = getParentKey(key, node.nodes);
      }
    }
  }
  return parentKey;
};
export default {
  components: {
    DataLabel,
    DataTree,
    Input,
    Button,
    Popover,
    message,
    SearchOutlined,
    QuestionCircleOutlined,
    Radio,
    RadioGroup,
    DatePicker,
    DoubleRightOutlined,
  },
  data() {
    return {
      height: "500px",
      width: "1200px",
      gData: [], //右侧label的数据
      gData1: [], //左侧部门数据采集授权
      gData2: [], ////右侧区域授权
      gData3: [], //右侧客户授权
      gData4: [], //右侧渠道授权
      gData5: [], //右侧品牌授权
      checkedKeys1: [],
      checkedKeys2: [],
      checkedKeys3: [],
      checkedKeys4: [],
      checkedKeys5: [],
      validity: 1, //单选框1永久2自定义
      datePicker: null, //日期
      disabled: true,
      tagCode: "",
      tagName: "",
    };
  },
  methods: {
    getData() {
      dataAll().then((res) => {
        let all = [
          {
            // disabled: true,
            id: "ALL",
            tag: "数据标签",
          },
        ];
        all[0].nodes = res.data.data;
        this.gData = all;
        if (this.$route.query.tag) {
          let parentKeyList = [];
          function getParentKeyList(key) {
            parentKeyList.push(getParentKey(key, all));
            let parentKey = getParentKey(key, all);
            if (parentKey != "ALL") {
              getParentKeyList(parentKey);
            }
          }
          getParentKeyList(this.$route.query.tag);
          this.$refs.DataLabel.onExpand(parentKeyList);
        }
      });
    },
    changeCheckedKeys(key, modul) {
      switch (modul) {
        case 1:
          this.checkedKeys1 = key;
          break;
        case 2:
          this.checkedKeys2 = key;
          break;
        case 3:
          this.checkedKeys3 = key;
          break;
        case 4:
          this.checkedKeys4 = key;
          break;
        case 5:
          this.checkedKeys5 = key;
          break;
        default:
          break;
      }
    },
    selectTree(keyList, tagName) {
      this.tagName = tagName;
      if (keyList.length === 0) {
        return;
      }
      let key = keyList[0];
      //当点到第一级的时候清空后面的列
      if (key === "ALL") {
        this.gData1 = [];
        this.gData2 = [];
        this.gData3 = [];
        this.gData4 = [];
        this.gData5 = [];
        this.checkedKeys1 = [];
        this.checkedKeys2 = [];
        this.checkedKeys3 = [];
        this.checkedKeys4 = [];
        this.checkedKeys5 = [];
        this.tagCode = key;
        return;
      }
      this.tagCode = key;
      this.disabled = true;
      function computedList(array, type = 0) {
        let checkedList = [];
        (function getList(array) {
          if (array.length > 0) {
            array.forEach((element) => {
              if (element.status) {
                if (type === 2 && element.pdCode !== "ALL") {
                  checkedList.push(element.pdCode + "/" + element.pdLevel);
                } else {
                  checkedList.push(element.pdCode);
                }
              }
              if (element.childList) {
                getList(element.childList);
              }
            });
          }
        })(array);
        return checkedList;
      }
      for (let i = 1; i <= 5; i++) {
        this.gData1 = [];
        dataList(key, i).then((res) => {
          let result = [];
          //省市区的code有重复的 单独拼接key
          if (i === 2) {
            function setKey(array) {
              array.forEach((el) => {
                if (el.pdCode === "ALL") {
                  el.key = el.pdCode;
                } else {
                  el.key = el.pdCode + "/" + el.pdLevel;
                }
                if (el.childList) {
                  setKey(el.childList);
                }
              });
            }
            setKey(res.data.data);
          }
          if (res.data.data[0].pdName !== "全选" && i !== 1) {
            result = [
              {
                id: null,
                pdCode: "ALL",
                key: "ALL",
                pdName: "全选",
                pdLevel: 0,
                status: 0,
                childList: [],
              },
            ];
            result[0].childList = res.data.data;
          } else {
            result = res.data.data;
          }
          switch (i) {
            case 1:
              this.gData1 = result || [];
              this.checkedKeys1 = computedList(result) || [];
              break;
            case 2:
              this.gData2 = result || [];
              this.checkedKeys2 = computedList(result, 2) || [];
              break;
            case 3:
              this.gData3 = result || [];
              this.checkedKeys3 = computedList(result) || [];
              break;
            case 4:
              this.gData4 = result || [];
              this.checkedKeys4 = computedList(result) || [];
              break;
            case 5:
              this.gData5 = result || [];
              this.checkedKeys5 = computedList(result) || [];
              break;
          }
        });
      }
    },
    submit() {
      function computedList(array, checkedKeys, pdType) {
        let dataList = [];
        (function getDataList(array, checkedKeys, pdType) {
          array.forEach((element) => {
            if (
              checkedKeys.includes(element.pdCode) ||
              checkedKeys.includes(element.pdCode + "/" + element.pdLevel)
            ) {
              dataList.push({
                pdType: pdType,
                pdCode: element.pdCode,
                pdLevel: element.pdLevel,
              });
            } else {
              if (pdType != 1 && element.childList) {
                getDataList(element.childList, checkedKeys, pdType);
              }
            }
            if (pdType == 1 && element.childList) {
              getDataList(element.childList, checkedKeys, pdType);
            }
          });
        })(array, checkedKeys, pdType);
        return dataList;
      }
      // console.log(this.checkedKeys1);
      let list1 = computedList(
        this.gData1,
        this.checkedKeys1.checked || this.checkedKeys1,
        1
      );
      let list2 = computedList(this.gData2, this.checkedKeys2, 2);
      let list3 = computedList(this.gData3, this.checkedKeys3, 3);
      let list4 = computedList(this.gData4, this.checkedKeys4, 4);
      let list5 = computedList(this.gData5, this.checkedKeys5, 5);
      if (!this.tagName) {
        let tagName;
        function getTagName(array, id) {
          array.forEach((element) => {
            if (element.id == id) {
              tagName = element.tag;
            }
            if (element.nodes) {
              getTagName(element.nodes, id);
            }
          });
        }
        getTagName(this.gData, this.tagCode);
        this.tagName = tagName;
      }
      saveConfiguration({
        tagCode: this.tagCode,
        tagName: this.tagName,
        expiredAt: this.datePicker,
        dataList: [...list1, ...list2, ...list3, ...list4, ...list5],
      }).then((res) => {
        if (res.data.success) {
          message.success("授权更改成功");
          this.selectTree([this.tagCode]);
          // console.log(this.checkedKeys2);
        }
      });
    },
    afterDel() {
      this.getData();
      this.tagCode = "";
    },
  },
  mounted() {
    this.height = document.body.clientHeight - 162 + "px";
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}
.between {
  justify-content: space-between;
}
.middle {
  align-items: center;
}
.tips {
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -20px;
  background: #008bcf;
}
.out {
  border-radius: 4px;
  background: #fff;
  border: 1px solid #ccc;
}
.titleLeft {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #ccc;
  font-weight: 900;
  padding-left: 20px;
}
.sub {
  position: absolute;
  bottom: 15px;
  right: 40px;
}
.treeBox {
  position: relative;
  padding-right: 20px;
  // padding-right: 10px;
  padding-top: 10px;
  .title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 900;
  }
  .icon {
    color: #008bcf;
    background: #e6fbff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgba($color: #008bcf, $alpha: 0.5);
    position: absolute;
    right: -2px;
    // right: -7px;
    z-index: 1;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.treeBox:nth-last-child(1) {
  padding-right: 0;
}
</style>